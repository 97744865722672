export const mainPlatforms = [
  'Instagram',
  'TikTok',
  'Twitter',
  'Snapchat',
  'Facebook',
]
export const otherPlatforms = [
  'YouTube',
  'Pinterest',
  'Twitch',
  'Website',
  'Other',
]

export const platforms = [...mainPlatforms, ...otherPlatforms]
